<template>
  <ion-page>
    <wordsHeader title="Publish Quotes" />
    <ion-content id="top" :fullscreen="true" padding>
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <ion-grid id="grid">
        <ion-row justify-content-center>
          <ion-col
            align-self-center
            size-md="6"
            size-lg="6"
            offset-lg="3"
            offset-md="3"
            size-xs="12"
          >
            <h2>Unpublished Quotes</h2>
          </ion-col>
        </ion-row>
        <ion-row color="primary" justify-content-center>
          <ion-col
            align-self-center
            size-md="6"
            size-lg="6"
            offset-lg="3"
            offset-md="3"
            size-xs="12"
          >
            <ion-spinner
              v-if="loading"
              name="circles"
              id="loadspinner"
              color="primary"
            ></ion-spinner>
            <p v-if="!quotes.length">No unpublished quotes...</p>
            <ion-list v-else>
              <ion-item-sliding v-for="q in quotes" :key="q.id">
                <ion-item>
                  <ion-label class="listitem">{{ q.title }}</ion-label>
                  <span v-if="q.published" class="badges"
                    ><img title="Online" src="/assets/img/check_online.png"
                  /></span>
                  <span v-else class="badges"
                    ><img title="Offline" src="/assets/img/check_offline.png"
                  /></span>
                  <span title="Swipe to edit" class="listitemsymbolright"
                    ><img src="/assets/img/swipe.png"
                  /></span>
                </ion-item>
                <ion-item-options side="end">
                  <ion-item-option color="dark" @click="qpublish(q.id)">
                    Publish
                  </ion-item-option>
                  <ion-item-option color="danger" @click="qdelete(q.id)">
                    Delete
                  </ion-item-option>
                  <ion-item-option color="primary" @click="qedit(q.id)">
                    V i e w
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonItem,
  IonPage,
  IonContent,
  IonLabel,
  IonSpinner,
} from "@ionic/vue";

export default {
  name: "QuotesPublish",
  data: () => {
    return {
      notification: "",
      UnPublishedQuoteQueryUrl: "/api/quote/publish/quotes",
      DeleteQuoteQueryUrl: "/api/quote/q/",
      PublishQuoteQueryUrl: "api/quote/publish/",
      quotes: {},
      loading: false,
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonPage,
    wordsHeader,
    IonList,
    IonItem,
    IonItemSliding,
    IonItemOption,
    IonItemOptions,
    IonLabel,
    IonSpinner,
  },
  methods: {
    getQuotes: function() {
      this.loading = true;
      axios
        .get(this.UnPublishedQuoteQueryUrl)
        .then((response) => {
          this.quotes = response.data;
          this.loading = false;
        })
        .catch(function(error) {
          this.notification = error.message;
        });
    },
    qdelete: function(quoteid) {
      axios
        .delete(this.DeleteQuoteQueryUrl + quoteid)
        .then(() => {
          this.notification = "Quote has been deleted.";
          this.getQuotes();
          document.querySelector("#top").scrollToTop();
        })
        .catch(function(error) {
          this.notification = error.message;
        });
    },
    qpublish: function(quoteid) {
      axios
        .put(this.PublishQuoteQueryUrl + quoteid)
        .then(() => {
          this.notification = "Quote has been published.";
          this.getQuotes();
          document.querySelector("#top").scrollToTop();
        })
        .catch(function(error) {
          this.notification = error.message;
        });
    },
    qedit: function(quoteid) {
      this.$router.push({ path: `/quotes/edit/${quoteid}` });
    },
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.getQuotes();
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.listitemsymbolright {
  float: right;
  width: 35px;
}

.badges {
  float: right;
  width: 20px;
  margin: 0px 15px 0px 0px;
}

#loadspinner {
  position: absolute;
  margin: 100px auto;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  display: block;
}

#grid {
  max-width: 100%;
}
</style>
