<template>
  <ion-page>
    <wordsHeader title="Settings" />
    <ion-content :fullscreen="true" padding>
      <div class="textblock" id="noti_alert">{{ notification }}</div>
      <form>
        <ion-grid id="grid">
          <ion-row color="primary" justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <div>
                <div class="ion-padding-top ion-padding-bottom">
                  Select language of the quotes
                </div>
                <ion-item>
                  <ion-select
                    v-if="languages && languages.length"
                    :value="selectedLang"
                    @ionChange="onChange($event)"
                    placeholder="please select..."
                  >
                    <ion-select-option
                      v-for="l in languages"
                      :key="l.id"
                      :value="l.lang"
                      >{{ l.desc }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="settingsmenu" v-if="isLoggedIn">
            <ion-col
              align-self-center
              size-md="12"
              size-lg="12"
              offset-lg="3"
              offset-md="3"
            >
              <div>
                <div class="ion-padding-top ion-padding-bottom">User Menu</div>
              </div></ion-col
            >

            <ion-col
              align-self-center
              size-md="2"
              size-lg="2"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <router-link to="/quotes/edit">
                <ion-button expand="block">
                  <ion-icon :src="i.list.md" size="large"></ion-icon>my Quotes
                </ion-button>
              </router-link>
            </ion-col>
            <ion-col align-self-center size-md="2" size-lg="2" size-xs="12">
              <router-link to="/quotes/new">
                <ion-button expand="block">
                  <ion-icon :src="i.add.md" size="large"></ion-icon>New Quote
                </ion-button>
              </router-link>
            </ion-col>
            <ion-col align-self-center size-md="2" size-lg="2" size-xs="12">
              <router-link to="/profile">
                <ion-button expand="block">
                  <ion-icon :src="i.person.md" size="large"></ion-icon>my
                  Profile
                </ion-button>
              </router-link>
            </ion-col>
            <ion-col align-self-center size-md="2" size-lg="2" size-xs="12">
              <router-link v-if="isAdmin" to="/publish">
                <ion-button expand="block" color="dark">
                  <ion-icon :src="i.bookmarks.md" size="large"></ion-icon
                  >Publish
                </ion-button>
              </router-link>
            </ion-col>
          </ion-row>
          <ion-row v-else class="settingsmenu">
            <ion-col
              align-self-center
              size-md="12"
              size-lg="12"
              offset-lg="3"
              offset-md="3"
            >
              <div class="ion-padding-top ion-padding-bottom">
                Please <router-link to="/login">login</router-link> to see the
                menu
              </div>
            </ion-col></ion-row
          >
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import * as allIcons from "ionicons/icons";
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
} from "@ionic/vue";

export default {
  name: "Settings",
  data: () => {
    return {
      LangQueryUrl: "/api/language",
      settings: {},
      languages: {},
      notification: "",
      i: allIcons,
    };
  },
  components: {
    IonContent,
    IonPage,
    wordsHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButton,
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.loadSettings();
  },
  methods: {
    loadSettings: function() {
      // get Settings
      this.settings = this.$store.state.settings;
      this.$store.state.settings.lang = localStorage.getItem("wdslg");

      // load languages dropdown
      axios
        .get(this.LangQueryUrl)
        .then((response) => {
          this.languages = response.data;
        })
        .catch(function(error) {
          this.notification = error.error;
        });
    },
    onChange: function(event) {
      this.settings.lang = event.target.value;
      this.$store.dispatch("saveSettings", this.settings);
    },
  },
  computed: {
    selectedLang() {
      return this.settings.lang;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>

<style scoped>
.settingsmenu {
  margin-top: 50px;
}

#grid {
  max-width: 100%;
}
</style>
