
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import store from "@/store/store";
import { ScreenOrientation } from "@ionic-native/screen-orientation";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  mounted() {
    // set to landscape
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);

    store.dispatch("tokenValidation");
  },
});
