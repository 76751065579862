import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Info from "../views/Info.vue";
import Settings from "../views/Settings.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Quotes from "../views/Quotes.vue";
import NewQuote from "../views/NewQuote.vue";
import EditQuote from "../views/EditQuote.vue";
import Profile from "../views/Profile.vue";
import PasswordRecovery from "../views/PasswordRecovery.vue";
import PasswordReset from "../views/PasswordReset.vue";
import QuotesPublish from "../views/QuotesPublish.vue";
import Store from "../store/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/info",
    name: "info",
    component: Info,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: PasswordRecovery,
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: PasswordReset,
  },
  {
    path: "/quotes/edit/:quoteid",
    name: "editquote",
    component: EditQuote,
    beforeEnter: (to, from, next) => {
      if (!Store.getters.isAuthenticated) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "/quotes/edit",
    name: "quotes",
    component: Quotes,
    beforeEnter: (to, from, next) => {
      if (!Store.getters.isAuthenticated) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "/quotes/new",
    name: "newquote",
    component: NewQuote,
    beforeEnter: (to, from, next) => {
      if (!Store.getters.isAuthenticated) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (!Store.getters.isAuthenticated) {
        next("/settings");
      } else {
        next();
      }
    },
  },
  {
    path: "/publish",
    name: "publish",
    component: QuotesPublish,
    beforeEnter: (to, from, next) => {
      if (!Store.getters.isAuthenticated || ! Store.getters.isAdmin) {
        next("/settings");
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
