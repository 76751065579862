import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from "../router";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: true });

const loginURL = "/api/login";
const logoutURL = "/api/logout";
const registerURL = "/api/register";
const tokenValidationURL = "/api/valid";

const store = createStore({
  plugins: [
    createPersistedState({
      key: "ews",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    slidecount: -1,
    isStartpage: true,
    token: localStorage.getItem("authorizationToken"),
    loggedInUser: "",
    postuserid: 0,
    settings: {
      lang: "de",
    },
    state: false,
    isAdmin: false,
  },

  // ACTIONS (asynchronous)
  actions: {
    logIn(context: any, data: any) {
      return new Promise((resolve, reject) => {
        axios
          .post(loginURL, { ...data })
          .then((response: any) => {
            context.state.state = true;
            context.commit("setLoggedInUser", response.data.user_id);
            context.commit("setIsAdmin", response.data.is_admin);
            context.commit("setToken", response.data.access_token);
            resolve(response);
          })
          .catch((error: any) => {
            localStorage.removeItem("authorizationToken");
            reject(error.response.data);
          });
      });
    },
    logOut(context) {
      axios
        .post(logoutURL, {
          token: context.state.token,
        })
        .then(() => {
          localStorage.removeItem("authorizationToken");
          context.commit("logout");
          router.push({ name: "home" });
        })
        .catch(function() {
          localStorage.removeItem("authorizationToken");
          context.commit("logout");
          router.push({ name: "home" });
        });
    },
    register(context: any, data: any) {
      return new Promise((resolve, reject) => {
        axios
          .post(registerURL, { ...data })
          .then((response: any) => {
            const token = response.data.access_token;
            context.commit("setLoggedInUser", response.data.user_id);
            context.commit("setToken", token);
            resolve(response);
          })
          .catch((error: any) => {
            localStorage.removeItem("authorizationToken");
            reject(error.response.data);
          });
      });
    },
    saveSettings(context, data: any) {
      localStorage.setItem("wdslg", data.lang);
      context.commit("setSettings", data);
    },
    tokenValidation(context) {
      if (context.state.token) {
        axios
          .get(tokenValidationURL, {
            headers: {
              Authorization: `Bearer ${context.state.token}`,
            },
          })
          .catch(function(error: any) {
            if (error.response.status != 200) {
              context.commit("logout");
            }
          });
      }
    },
  },

  // MUTATIONS ( set the state )
  mutations: {
    setLoggedInUser(state: any, payload: any) {
      state.loggedInUser = payload;
    },
    setIsAdmin(state: any, payload: any) {
      state.isAdmin = payload;
    },
    setToken(state: any, payload: any) {
      state.token = payload;
      localStorage.setItem("authorizationToken", payload);
    },
    slideIncrement(state: any) {
      state.slidecount++;
    },
    setPostUserID(state: any, payload: number) {
      state.postuserid = payload;
    },
    setSettings(state: any, payload: object) {
      state.settings = payload;
    },
    logout(state: any) {
      localStorage.removeItem("authorizationToken");
      state.token = "";
      state.state = false;
    },
  },

  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
  },
});

export default store;
