<template>
  <ion-page>
    <wordsHeader title="New Quote" />
    <ion-content :fullscreen="true" padding>
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <form ref="form" @submit.prevent="submit()">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <h2>New Quote</h2>
            </ion-col>
          </ion-row>
          <ion-row color="primary" justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <div padding>
                <ion-item>
                  <ion-input
                    name="title"
                    type="text"
                    placeholder="Your quote title"
                    required
                    v-model="formData.title"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div padding>
                <ion-item>
                  <IonTextarea
                    class="txtContent"
                    rows="10"
                    placeholder="Your quote text here..."
                    v-model="formData.content"
                    @keyup.enter="submitKeyEnter()"
                    required
                  ></IonTextarea>
                </ion-item>
              </div>
              <div padding>
                <ion-item>
                  <ion-select
                    v-if="languages && languages.length"
                    v-model="formData.language_id"
                    placeholder="Language of the quote"
                  >
                    <ion-select-option
                      v-for="l in languages"
                      :key="l.id"
                      :value="l.id"
                      >{{ l.desc }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </div>
              <div padding>
                <ion-item>
                  <ion-input
                    name="link"
                    type="text"
                    placeholder="Custom Link (will be shown under the quote)"
                    v-model="formData.link"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div padding>
                <ion-button
                  size="large"
                  type="submit"
                  expand="block"
                  color="light"
                  >Submit</ion-button
                >
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <p class="note">
                Please note:<br>Your submitted quote will first be reviewed and
                only visible after approval from Everwords.
              </p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import axios from "axios";
import wordsHeader from "@/components/Header.vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";

export default {
  name: "NewQuote",
  data: () => {
    return {
      LangQueryUrl: "/api/language",
      notification: "",
      formData: {},
      NewQuoteQueryUrl: "/api/quote",
      languages: {},
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonPage,
    wordsHeader,
    IonButton,
    IonInput,
    IonItem,
    IonTextarea,
    IonSelect,
    IonSelectOption,
  },
  methods: {
    submit: function() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.formData.user_id = this.userId;

      axios
        .post(this.NewQuoteQueryUrl, this.formData)
        .then((response) => {
          this.quotes = response.data;
          this.$router.push({ name: "quotes" });
        })
        .catch((error) => {
          this.notification = error.response.data.error;
        });
    },
    submitKeyEnter: function() {
      this.$refs.form.requestSubmit();
    },
    loadLanguages: function() {
      // load languages dropdown
      axios
        .get(this.LangQueryUrl)
        .then((response) => {
          this.languages = response.data;
        })
        .catch((error) => {
          this.notification = error.response.data.error;
        });
    },
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.loadLanguages();
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    userId() {
      return this.$store.state.loggedInUser;
    },
  },
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

ion-input,
ion-select {
  font-family: "Ubuntu Mono", monospace !important;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

ion-select {
  color: rgba(255, 255, 255, 0.4) !important;
  opacity: 1;
}

.note {
  color: #2675B7;
}
</style>
