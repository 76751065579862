<template>
  <ion-page>
    <wordsHeader title="Password Recovery" />
    <ion-content :fullscreen="true" padding>
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <form ref="form" @submit.prevent="recover()">
        <ion-grid>
          <ion-row color="primary" justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <div padding>
                <ion-item>
                  <ion-input
                    name="email"
                    type="email"
                    placeholder="your@email.com"
                    required
                    v-model="formdata.email"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div padding>
                <ion-button
                  size="large"
                  type="submit"
                  expand="block"
                  color="light"
                  >Recover your password</ion-button
                >
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <div class="textblock">
        <router-link to="/login"><ion-button>Login</ion-button> </router-link>
        <router-link to="/register"
          ><ion-button>Register</ion-button>
        </router-link>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";

import {
  IonPage, 
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
} from "@ionic/vue";

export default {
  name: "Password Recovery",
  data: () => {
    return {
      GetPWRecoveryUrl: "/api/password/email",
      notification: "",
      formdata: {}
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonItem,
    IonContent,
    IonPage,
    wordsHeader,
  },
  mounted() {
    this.$store.state.isStartpage = true;
  },
  methods: {
    recover: function () {
      axios.post(this.GetPWRecoveryUrl, this.formdata)
        .then((response) => {
          this.notification = response.data.success.message;
        })
        .catch(error => {
          this.notification = error.response.data.error.message;
        });
    }, 
    submitKeyEnter: function () {
      this.$refs.form.requestSubmit();
    }     
  }
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

ion-input {
  font-family: "Ubuntu Mono", monospace !important;
}
</style>