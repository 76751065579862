<template>
  <ion-page>
    <wordsHeader title="Register" />
    <ion-content :fullscreen="true" padding>
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <form ref="form" @submit.prevent="register()">
        <ion-grid>
          <ion-row color="primary" justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <div padding>
                <ion-item>
                  <ion-input
                    name="username"
                    type="text"
                    placeholder="username"
                    required
                    v-model="formData.username"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    name="name"
                    type="text"
                    placeholder="your name"
                    required
                    v-model="formData.name"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    name="email"
                    type="email"
                    placeholder="your@email.com"
                    required
                    v-model="formData.email"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    name="password"
                    type="password"
                    placeholder="password"
                    required
                    v-model="formData.password"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    name="password_confirmation"
                    type="password"
                    placeholder="retype your password"
                    required
                    v-model="formData.password_confirmation"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div padding>
                <ion-button
                  size="large"
                  type="submit"
                  expand="block"
                  color="light"
                  >Register</ion-button
                >
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <div class="textblock">
        <router-link to="/login"><ion-button>Login</ion-button> </router-link>
        <router-link to="/password-recovery"
          ><ion-button>Forgot Password</ion-button>
        </router-link>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import wordsHeader from "@/components/Header.vue";

import {
  IonPage, 
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
} from "@ionic/vue";

export default {
  name: "Register",
  data: () => {
    return {
      notification: "",
      formData: {
        username: "",
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      },
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    IonItem,
    IonContent,
    IonPage,
    wordsHeader,
  },
  methods: {
    register: function () {
      this.$store
        .dispatch("register", this.formData)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.notification = error.errors[Object.keys(error.errors)[0]][0]
        });
    },
    submitKeyEnter: function () {
      this.$refs.form.requestSubmit();
    },     
  },
  mounted() {
    this.$store.state.isStartpage = true;
  },
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

ion-input {
  font-family: "Ubuntu Mono", monospace !important;
}
</style>