<template>
  <ion-page>
    <wordsHeader title="Profile" />
    <ion-content :fullscreen="true" padding>
      <div v-if="notification" class="textblock" id="noti_alert">
        {{ notification }}
      </div>
      <form ref="form" @submit.prevent="submit()">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <h2>User profile</h2>
            </ion-col>
          </ion-row>
          <ion-row color="primary" justify-content-center>
            <ion-col
              align-self-center
              size-md="6"
              size-lg="6"
              offset-lg="3"
              offset-md="3"
              size-xs="12"
            >
              <div padding>
                <ion-avatar class="avatar_circle">
                  <img :src="user.avatar" alt="avatar" />
                </ion-avatar>

                <ion-button
                  class="uploadButton"
                  color="light"
                  @click="getAvatarFileDialogue"
                >
                  <span class="ion-text-left"
                    >Upload avatar (JPG, PNG)</span
                  ></ion-button
                >
                <input
                  id="getAvatarFile"
                  style="display:none"
                  ref="upload"
                  type="file"
                  name="avatar-upload"
                  accept="image/jpeg, image/png"
                  @change="encodeImageFileAsURL($event.target.files)"
                />
              </div>
              <div class="formelement" padding>
                <p class="label">Email:</p>
                <ion-item>
                  <ion-input
                    name="email"
                    type="text"
                    placeholder="Your Email"
                    required
                    v-model="user.email"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div class="formelement" padding>
                <p class="label">Username:</p>
                <ion-item>
                  <ion-input
                    name="username"
                    type="text"
                    placeholder="Your username"
                    required
                    v-model="user.username"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div class="formelement" padding>
                <p class="label">Personal link:</p>
                <ion-item>
                  <ion-input
                    name="userlink"
                    type="text"
                    placeholder="Your personal link"
                    required
                    v-model="user.userlink"
                    @keyup.enter="submitKeyEnter()"
                  ></ion-input>
                </ion-item>
              </div>
              <div class="formelement" padding>
                <p class="label">About you:</p>
                <ion-item>
                  <IonTextarea
                    class="txtContent"
                    rows="10"
                    placeholder="Something about you"
                    v-model="user.about"
                    @keyup.enter="submitKeyEnter()"
                    required
                  ></IonTextarea>
                </ion-item>
              </div>
              <div padding>
                <ion-button
                  size="large"
                  type="submit"
                  expand="block"
                  color="light"
                  >Save</ion-button
                >
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
const axios = require("axios").default;
import wordsHeader from "@/components/Header.vue";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonContent,
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
  IonAvatar,
} from "@ionic/vue";

export default {
  name: "Profile",
  data: () => {
    return {
      notification: "",
      UserProfileQueryUrl: "/api/user",
      UpdateUserProfileQueryUrl: "/api/user",
      user: {},
    };
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonButton,
    IonInput,
    IonItem,
    IonTextarea,
    wordsHeader,
    IonAvatar,
  },
  methods: {
    getUser: function() {
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.token}` },
      };

      axios
        .get(this.UserProfileQueryUrl, config)
        .then((response) => {
          this.user = response.data;
        })
        .catch(function(error) {
          this.notification = error.response.data.message;
        });
    },
    submitKeyEnter: function() {
      this.$refs.form.requestSubmit();
    },
    submit: function() {
      axios
        .put(
          this.UpdateUserProfileQueryUrl + "/" + this.$store.state.loggedInUser,
          this.user
        )
        .then(() => {
          this.notification = "Your user profile has been updated.";
        })
        .catch((error) => {
          this.notification = error.response.data.error;
        });
    },
    encodeImageFileAsURL: function(eFile) {
      if(!this.validateFileType(eFile[0].name)) { 
        this.notification = "Only JPG and PNG files are allowed.";
        return;
      } else {
        this.notification = "";
      }
      const file = eFile[0];
      const reader = new FileReader();

      reader.onloadend = function() {
        this.user.avatar = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);

    },
    validateFileType: function(eFile) {
        const fileName = eFile;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
            return true;
        }
        return false;  
    },
    getAvatarFileDialogue() {
      document.getElementById('getAvatarFile').click();
    }  
  },
  mounted() {
    this.$store.state.isStartpage = true;
    this.getUser();
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
  },
};
</script>

<style scoped>
#noti_alert {
  margin: 20px auto;
  text-align: center;
  font-family: "Ubuntu Mono", monospace;
  min-height: 30px;
}

.textblock {
  margin: 0 auto;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.uploadButton {
  margin: 30px 0px 5px 0px;
}

.formelement {
  padding-top: 5px;
}
</style>
