<template>
  <ion-header>
    <ion-toolbar color="light">
      <ion-buttons v-if="title != 'Words'" slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title :class="{ titleHome: isHome }">{{ title }}</ion-title>
      <ion-buttons slot="end">
        <span @click="clickhome()">
          <router-link to="/" exact>
            <ion-button title="Home">
              <ion-icon :src="i.home.md" size="large"></ion-icon>
            </ion-button>
          </router-link>
        </span>
        <router-link to="/login" v-if="!isLoggedIn">
          <ion-button title="Login">
            <ion-icon :src="i.logIn.md" size="large"></ion-icon>
          </ion-button>
        </router-link>
        <router-link to="/" v-else>
          <ion-button title="Logout" @click="logOut">
            <ion-icon :src="i.logOut.md" size="large"></ion-icon>
          </ion-button>
        </router-link>
        <router-link to="/settings">
          <ion-button title="Settings">
            <ion-icon :src="i.settings.md" size="large"></ion-icon>
          </ion-button>
        </router-link>
        <router-link to="/info">
          <ion-button title="Info">
            <ion-icon
              :src="i.informationCircleOutline.md"
              size="large"
            ></ion-icon>
          </ion-button>
        </router-link>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import * as allIcons from "ionicons/icons";

import {
  IonTitle,
  IonHeader,
  IonIcon,
  IonButton,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";

export default {
  name: "wordsHeader",
  components: {
    IonTitle,
    IonIcon,
    IonHeader,
    IonButton,
    IonToolbar,
    IonButtons,
    IonBackButton,
  },
  props: {
    title: String,
  },
  data: () => {
    return {
      i: allIcons,
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut");
    },
    clickhome() {
      this.$store.state.isStartpage = true;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    isHome() {
      return this.title == "Words";
    },
  },
};
</script>

<style scoped>
ion-title {
  text-align: left;
}

.titleHome {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}
</style>
